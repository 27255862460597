import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

function CustomDefaultNavbarMobile({ logo, button, buttonAction, open }) {
  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        py={2}
        sx={{
          position: "absolute",
          top: "100%", // Position right below the main navbar
          zIndex: 9,
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark overlay for the mobile menu
        }}
      >
        {/* Render Button if exists */}
        {button && (
          <MKButton
            onClick={buttonAction}
            variant="contained"
            color="info"
            sx={{ mb: 2 }}
            size="medium"
          >
            {button}
          </MKButton>
        )}

        {/* Render Logo */}
        <MKBox
          component={Link}
          to="/"
          display="flex"
          justifyContent="center"
          alignItems="center"
          lineHeight={1}
          mb={2}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "auto", width: "120px" }} // Smaller size for mobile view
          />
        </MKBox>

        {/* Add any other mobile-specific items here */}
        <MKButton
          component={Link}
          to="/"
          variant="outlined"
          color="info"
          size="small"
          fullWidth
          sx={{ mt: 2 }}
        >
          Home
        </MKButton>
      </MKBox>
    </Collapse>
  );
}

CustomDefaultNavbarMobile.propTypes = {
  logo: PropTypes.string.isRequired,
  button: PropTypes.string,
  buttonAction: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default CustomDefaultNavbarMobile;
