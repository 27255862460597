import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Icon } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import breakpoints from "assets/theme/base/breakpoints";
import CustomDefaultNavbarMobile from "./CustomDefaultNavbarMobile";

function CustomDefaultNavbar({ logo, button, buttonAction }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    // Set display state initially and on window resize
    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  // respponsive styles for mobile
  const logoSize = mobileView ? "150px" : "250px";
  const buttonSize = mobileView ? "small" : "medium";

  return (
    <>
      <Container
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          backgroundColor: "transparent",
        }}
      >
        <MKBox
          py={2}
          px={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          //position="absolute"
          sx={{
            backgroundColor: "transparent",
            position: "sticky",
            //top: 0,
            //zIndex: 10,
          }}
        >
          {button && (
            <MKButton
              onClick={buttonAction}
              variant="outlined"
              color="white"
              size={buttonSize}
              sx={{
                ml: 2,
              }}
            >
              <Icon sx={{ fontSize: 16 }}>home</Icon> {button}
            </MKButton>
          )}

          {/* Right-aligned logo */}
          <MKBox
            component={Link}
            to="/"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            lineHeight={1}
            ml="auto"
          >
            <img src={logo} alt="logo" style={{ height: "auto", width: logoSize }} />
          </MKBox>

          {/* Mobile Navbar Toggle */}
        </MKBox>

        {/* Placeholder for Mobile Navbar */}
        {mobileView && mobileNavbar && (
          <MKBox
            sx={{
              position: "absolute",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              zIndex: 9,
            }}
          >
            {/* Your mobile navigation content here */}
            {/* Example: Home button or quick links */}
            <MKButton
              component={Link}
              to="/"
              variant="contained"
              color="info"
              fullWidth
              onClick={openMobileNavbar}
            >
              Home
            </MKButton>
          </MKBox>
        )}
      </Container>
      {mobileView && (
        <CustomDefaultNavbarMobile
          logo={logo}
          button={button}
          buttonAction={buttonAction}
          open={mobileNavbar}
        />
      )}
    </>
  );
}

// Typechecking props for the SimpleNavbar
CustomDefaultNavbar.propTypes = {
  logo: PropTypes.string.isRequired,
  button: PropTypes.string,
  buttonAction: PropTypes.func,
};

export default CustomDefaultNavbar;
