// CustomFooter.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
//import PropTypes from "prop-types";

// @mui material components
//import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
//import Stack from "@mui/material/Stack";
//import IconButton from "@mui/material/IconButton";

// @mui icons
//import FacebookIcon from "@mui/icons-material/Facebook";
//import CloseIcon from "@mui/icons-material/Close"; // Represents 'X'
//import InstagramIcon from "@mui/icons-material/Instagram";
//import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CustomFooter() {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handlePrivacyClick = () => {
    navigate("/privacy");
  };

  return (
    <MKBox component="footer" py={3} px={1} mt={6}>
      <Grid container justifyContent="space-between" alignItems="center">
        {/* Left Side: Copyright */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ textAlign: { xs: "center", md: "left" }, mb: { xs: 2, md: 0 } }}
        >
          <MKTypography variant="body2" color="white" fontWeight="regular">
            &copy; {currentYear} AgriSA
          </MKTypography>
        </Grid>

        {/* Center: Social Icons */}
        <Grid item xs={12} md={4} sx={{ textAlign: "center", mb: { xs: 2, md: 0 } }}>
          {/*
          <Stack direction="row" spacing={2} justifyContent="center">
            <Link href="https://www.facebook.com/AgriSA" target="_blank" rel="noreferrer">
              <IconButton
                aria-label="Facebook"
                color="#fff"
                sx={{
                  color: "#fff",
                  backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: semi-transparent background
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                <FacebookIcon />
              </IconButton>
            </Link>
            <Link href="https://twitter.com/AgriSA" target="_blank" rel="noreferrer">
              <IconButton
                aria-label="X"
                color="#fff"
                sx={{
                  color: "#fff",
                  backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: semi-transparent background
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                <CloseIcon /> 
              </IconButton>
            </Link>
            <Link href="https://www.instagram.com/AgriSA" target="_blank" rel="noreferrer">
              <IconButton
                aria-label="Instagram"
                color="#fff"
                sx={{
                  color: "#fff",
                  backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: semi-transparent background
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                <InstagramIcon />
              </IconButton>
            </Link>
            <Link href="https://www.linkedin.com/company/agrisa" target="_blank" rel="noreferrer">
              <IconButton
                aria-label="LinkedIn"
                color="#fff"
                sx={{
                  color: "#fff",
                  backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: semi-transparent background
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                <LinkedInIcon />
              </IconButton>
            </Link>
    </Stack>
    */}
        </Grid>

        {/* Right Side: Privacy Policy */}
        <Grid item xs={12} md={4} sx={{ textAlign: { xs: "center", md: "right" } }}>
          <MKTypography
            onClick={handlePrivacyClick}
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Privacy Policy
          </MKTypography>
        </Grid>
      </Grid>
    </MKBox>
  );
}

// Typechecking props for the CustomFooter
CustomFooter.propTypes = {
  // No props needed as per current requirements
};

export default CustomFooter;
