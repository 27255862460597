/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import macTabletImage from "assets/images/landing-site/macTabletImage.png";

function Testimonials() {
  const image = macTabletImage;

  return (
    <MKBox component="section" position="relative" py={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={0}>
            <Grid container>
              {/*
              <Grid
                item
                xs={12}
                xl={3}
                sx={{
                  px: { xs: 6, md: 3, ml: 4, lg: 6, xl: 6 },
                  mt: { xs: 3, md: 6, ml: 6 },
                  mb: { xs: 3, md: "auto" },
                  //lineHeight: 1,
                  alignSelf: "flex-start",
                }}
                //alignContent="top"
              >
                <MKTypography variant="h3" color="white" sx={{ fontWeight: "bold" }}>
                  Our Vision for Agriculture
                </MKTypography>
              </Grid>
              */}

              <Grid
                item
                xs={12}
                md={6}
                xl={9}
                pl={3}
                sx={{
                  position: "relative",
                  px: { xs: 1, md: 2 },
                  mt: { xs: 3, md: 3 },
                  my: { xs: 0, md: 6 },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <MKTypography
                  variant="h3"
                  color="white"
                  pb={2}
                  //px={2}
                  pt={1}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "20px", md: "26px", lg: "32px" },
                    px: { xs: 1, md: 2, lg: 3 },
                  }}
                >
                  Our Vision for Agriculture
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  //pl={3}
                  sx={{
                    fontSize: { xs: "1.2rem", md: "1.15rem", lg: "1.2rem" },
                    px: { xs: 1, md: 2, lg: 3 },
                  }}
                >
                  At AgricultureSA.io, we believe in the power of organized, objective data to drive
                  meaningful change. Our mission is to cultivate a unified, transparent system where
                  agricultural data empowers every stakeholder. By unlocking the potential of
                  geographic and analytical insights, we aim to address the challenges of our
                  time—creating a resilient, sustainable future for South African agriculture.
                </MKTypography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                sx={{ position: "relative", px: 6, mt: { xs: -6, md: -6, lg: -12 } }}
              >
                <MKBox
                  component="img"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="350px"
                  width="180%"
                  position="relative"
                  mt={0}
                  sx={{
                    position: "relative",
                    marginLeft: { xs: -3, md: 0, lg: 8 },
                  }}
                  //ml={12}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
