// PlatformCard.jsx

import PropTypes from "prop-types";
import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PlatformCard({ icon, title, description, image }) {
  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { rgba, linearGradient },
        borders: { borderRadius },
      }) => ({
        backgroundImage: `${linearGradient(
          rgba(gradients.dark.main, 0.3),
          rgba(gradients.dark.state, 0.3)
        )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.lg,
        //minimumWidth: "200px",
        width: "100%",
        //height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        color: "white",
        opacity: 0.9,
        boxShadow: ({ boxShadows: { lg } }) => lg,
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-10px)",
          boxShadow: ({ boxShadows: { xl } }) => xl,
        },
      })}
    >
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        textAlign="left"
        p={3}
        //lineHeight={1}
      >
        {/* Icon without background container */}
        <MKBox display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
          {typeof icon === "string" ? (
            <Icon fontSize="large" sx={{ color: "#fff" }}>
              {icon}
            </Icon>
          ) : (
            React.cloneElement(icon, { sx: { color: "#fff", fontSize: "2rem" } })
          )}
        </MKBox>

        {/* Title */}
        <MKTypography
          //variant="h5"
          fontWeight="bold"
          color="white"
          mb={1}
          sx={{
            fontSize: {
              xs: "1.0rem",
              sm: "1.15rem",
              md: "1.25rem",
              lg: "1.5rem",
            },
          }}
        >
          {title}
        </MKTypography>

        {/* Description */}
        <MKTypography variant="body2" color="white">
          {description}
        </MKTypography>
      </MKBox>
    </Card>
  );
}

PlatformCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default PlatformCard;
