/**
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  This routes file has been streamlined to include only the necessary routes:
  - Coworking Landing Page
  - Privacy Policy Page

  Any additional routes have been removed to maintain a clean and focused routing structure.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "layouts/pages/landing-pages/coworking";
import Privacy from "layouts/pages/support/privacy";

const routes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 2, // Number of columns in the dropdown
    rowsPerColumn: 1, // Number of rows per column
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "Coworking",
            route: "/", // Set as the default home page
            component: <CoworkingPage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "Privacy Policy",
            route: "/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
];

export default routes;
