/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import { keyframes } from "@mui/system";

// Material Kit 2 PRO React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CustomDefaultNavbar from "examples/Navbars/DefaultNavbar/CustomDefaultNavbar";
//import DefaultFooter from "examples/Footers/DefaultFooter";
import CustomFooter from "examples/Footers/CustomFooter";

// Coworking page sections
//import Information from "pages/LandingPages/Coworking/sections/Information";
import Testimonials from "pages/LandingPages/Coworking/sections/Testimonials";
//import AboutUs from "pages/LandingPages/Coworking/sections/AboutUs";
//import Places from "pages/LandingPages/Coworking/sections/Places";
import Quote from "pages/LandingPages/Coworking/sections/Quote";
//import backgroundSvgUrl from "pages/LandingPages/Coworking/sections/background";
//import BackgroundSvg from "pages/LandingPages/Coworking/sections/background";
//import backgroundSvgImage from "pages/LandingPages/Coworking/sections/backgroundSVGImage.png";
import PlatformCard from "examples/Cards/InfoCards/PlatformCard";
import CallToAction from "pages/LandingPages/Coworking/sections/CallToAction";
//import PDFViewer from "pages/LandingPages/Coworking/sections/PDFViewer";

import collaboration from "assets/images/landing-site/collaboration.jpg";
import integrity from "assets/images/landing-site/integrity.jpg";
import innovation from "assets/images/landing-site/innovation.jpg";
import sustainability from "assets/images/landing-site/sustainability.jpg";
import ferns from "assets/images/landing-site/ferns.png";

import ShieldIcon from "@mui/icons-material/Shield";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

// Routes
//import routes from "routes";
//import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/landing-site/bgHeaderImage.png";
import logo from "assets/images/landing-site/AgricultreSa logo.png";
//import QRcode from "assets/images/landing-site/QRcode.png";

/*
<Grid item xs={12} md={12} lg={12} xl={12}>
                <MKBox
                  minHeight="160px"
                  width="160px"
                  sx={{
                    backgroundImage: ({
                      functions: { linearGradient, rgba },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.0),
                        rgba(gradients.dark.state, 0.0)
                      )}, url(${QRcode})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                  }}
                ></MKBox>
              </Grid>
*/

const blurIn = keyframes`
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
`;

function Coworking() {
  const [openPDF, setOpenPDF] = useState(false);

  const handleOpenPDF = () => setOpenPDF(true);
  const handleClosePDF = () => setOpenPDF(false);

  /*
  const handleSharePDF = async () => {
    const shareData = {
      title: "Agenda",
      text: "Check out teh conference agenda!",
      url: `${window.location.origin}/assets/pdfs/privacy-policy.pdf`,
    };
    

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log("Privacy Policy shared successfully");
      } catch (error) {
        console.error("Error sharing Privacy Policy:", error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      // You can open a share dialog or copy the link to the clipboard
      alert("Share functionality is not supported in your browser.");
    }
  };
  */

  return (
    <>
      {/*
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      */}
      <CustomDefaultNavbar logo={logo} />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              color="white"
              fontWeight="light"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                  //fontWeight: fontWeightLight,
                },
              })}
            >
              Organizing the data in Agriculture
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              Unlock insights that is transforming the future of South African agriculture.
            </MKTypography>
            <Grid container justifyContent={"center"} xs={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Stack direction="row" spacing={1} mt={6} mb={3}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    component="a"
                    href="https://share-eu1.hsforms.com/1TSSeeAwBRGywAoKDV6iLnA2dypu8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    stay rooted in updates
                  </MKButton>
                  <MKButton
                    variant="gradient"
                    color="white"
                    onClick={() => handleOpenPDF()}
                    //component="a"
                    //href="https://agriculturesa.io/resources/greenhorisonsagenda.pdf"
                    //target="_blank"
                    //rel="noopener noreferrer"
                  >
                    View Green Horizons Agenda
                  </MKButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { black }, functions: { rgba } }) => rgba(black.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox display="flex" justifyContent="center" alignItems="center">
          <MKTypography
            color="white"
            variant="body1"
            fontWeight="light"
            sx={{
              fontSize: { xs: 32, md: 42, lg: 82, xl: 96 },
              textAlign: "center",
              animation: `${blurIn} 2s ease-out forwards`,
            }}
          >
            COMING FEBRUARY 2025
          </MKTypography>
        </MKBox>
        <Testimonials />
        <Quote />
        <MKBox position="relative" height="auto" py={8} px={6} width="100%">
          {/* SVG Background */}
          <MKBox
            sx={{
              position: "absolute",
              top: 50,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -1,
              opacity: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0.0,
                xl: 0,
              },
              overflow: "hidden",
            }}
          ></MKBox>

          {/* Overlay Content */}
          <Container>
            <Grid container spacing={4} justifyContent="center" alignItems="stretch" py={3}>
              {/* Platform Card 1 */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PlatformCard
                  icon={<ShieldIcon />}
                  title="Integrity"
                  description="We uphold the highest standards of accuracy and reliability in all our developments."
                  image={integrity}
                  direction="center"
                />
              </Grid>

              {/* Platform Card 2 */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PlatformCard
                  icon={<GroupWorkIcon />}
                  title="Collaboration"
                  description="We believe in fostering partnerships across the agricultural sector to drive shared success."
                  image={collaboration}
                  direction="center"
                />
              </Grid>

              {/* Platform Card 3 */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PlatformCard
                  icon={<LightbulbIcon />}
                  title="Innovation"
                  description="We embrace technological advancements to continuously enhance data accessibility and insights."
                  image={innovation}
                  direction="center"
                />
              </Grid>

              {/* Platform Card 4 */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <PlatformCard
                  icon={<AllInclusiveIcon />}
                  title="Sustainability"
                  description="We are committed to promoting practices that ensure the long-term health of our agricultural systems."
                  image={sustainability}
                  direction="center"
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <CallToAction
          image={ferns}
          titleLeft="Be part of change."
          titleRight="Sign up for early access and updates."
          buttonText="Follow the growth"
          buttonLink="#"
        />
      </Card>
      <MKBox pt={0} px={3} mt={2}>
        <CustomFooter />
      </MKBox>
      <Modal
        open={openPDF}
        onClose={handleClosePDF}
        aria-labelledby="pdf-modal-title"
        aria-describedby="pdf-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "80%" },
            height: { xs: "80%", md: "90%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MKTypography id="pdf-modal-title" variant="h6" component="h2" mb={2}>
            Agenda PDF
          </MKTypography>
          <Box
            sx={{
              flexGrow: 1,
              border: "none",
            }}
          >
            <iframe
              src="https://agriculturesa.io/resources/greenhorisonsagenda.pdf" // External URL
              // If hosted locally, use "/assets/pdfs/greenhorisonsagenda.pdf"
              title="Agenda PDF"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            >
              This browser does not support PDFs. Please download the PDF to view it:{" "}
              <a href="https://agriculturesa.io/resources/greenhorisonsagenda.pdf">Download PDF</a>
            </iframe>
          </Box>
          <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
            <MKButton variant="gradient" color="dark" onClick={handleClosePDF}>
              Close
            </MKButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default Coworking;
