/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

import CustomDefaultNavbar from "examples/Navbars/DefaultNavbar/CustomDefaultNavbar";
import CustomFooter from "examples/Footers/CustomFooter";

// Routes
//import routes from "routes";
//import footerRoutes from "footer.routes";
import logo from "assets/images/landing-site/AgricultreSa logo.png";

function Privacy() {
  const navigate = useNavigate();

  // Function to handle the Home button click
  const handleHomeClick = () => {
    navigate("/");
  };
  return (
    <>
      <CustomDefaultNavbar logo={logo} button="Home" buttonAction={handleHomeClick} />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    AgricultureSA.io Privacy Policy
                  </MKTypography>
                  <MKTypography variant="body1" color="white" opacity={0.8}>
                    Version: 1 | Date: 2024-10-03 | Classification: Public
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    1. Purpose
                  </MKTypography>
                  <MKTypography variant="body1" color="text" paragraph>
                    In today’s digital landscape, privacy and data security are crucial. Agri South
                    Africa NPC (“AgriSA”) is committed to safeguarding personal and corporate
                    information. AgricultureSA.io is an initiative of AgriSA and operates under the
                    name of Agri South Africa NPC until further notice.
                  </MKTypography>
                  <MKTypography variant="body1" color="text" paragraph>
                    This Statement of Privacy applies to the{" "}
                    <a href="https://www.agriculturesa.io" target="_blank" rel="noreferrer">
                      https://www.agriculturesa.io
                    </a>{" "}
                    website and governs data collection and usage. By using the Website, you consent
                    to the data practices described in this statement.
                  </MKTypography>
                  {/* Contacting Us */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Contacting Us
                  </MKTypography>
                  <MKTypography variant="body1" color="text" paragraph>
                    For inquiries regarding our privacy practices, please reach out via:
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    <strong>Email:</strong> admin@agriculturesa.io
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    <strong>Postal Address:</strong> Block D, Inkwazi Office Park, 1249 Embankment
                    Road, Centurion, Gauteng, South Africa, 0157
                  </MKTypography>
                  {/* Commitment to Privacy */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    2. Our Commitment to Privacy
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    AgriSA is dedicated to respecting and protecting the privacy of the data we
                    handle, guided by the following principles:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Transparency:</strong> We prioritize openness about how we collect,
                        use, and protect the information provided to us.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Security:</strong> We implement advanced security measures to
                        prevent unauthorized access, disclosure, alteration, or destruction of data.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Compliance:</strong> We strive to comply with applicable privacy
                        laws, including the GDPR and POPI Act.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Client Control and Consent:</strong> We respect our clients&apos;
                        rights to control their personal information.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Continuous Improvement:</strong> We regularly review and update our
                        privacy and data protection practices.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/* Collection of Personal Information */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    3. Collection of Personal Information
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA collects personally identifiable information, such as your e-mail
                        address, name, surname, or telephone number. AgriSA also collects anonymous
                        demographic information, such as ZIP code, age, gender, and interests.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        Information is collected automatically about your computer hardware and
                        software, such as IP address, browser type, access times, and referring
                        website addresses. This information is used to operate and improve the
                        service.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Collection Methods */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    4. Collection Methods
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        Information is collected through our website, survey forms, and QR codes.
                        This data collection helps enhance our services and improve user experience.
                        We ensure that data collection is lawful, fair, and transparent, adhering to
                        the principle of data minimization.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Purpose of Processing */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    5. Purpose of Processing
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA collects and uses your personal information to operate the
                        AgricultureSA.io website and deliver the services you have requested. We
                        also use your information to inform you of other products or services
                        available from AgriSA and its affiliates. Occasionally, we may contact you
                        via surveys to conduct research about your opinions on current or potential
                        new services.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        We keep track of the websites and pages our customers visit within AgriSA to
                        determine which services are the most popular. This data is used to deliver
                        customized content and advertising to customers interested in particular
                        subject areas.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Data Sharing and Disclosure */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    6. Data Sharing and Disclosure
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA does not sell, rent, or lease its customer lists to third parties.
                        AgriSA may, from time to time, contact you on behalf of external business
                        partners about a particular offering that may be of interest to you. In
                        those cases, your unique personally identifiable information (e-mail
                        address, name, surname, telephone number) is not transferred to the third
                        party. Additionally, AgriSA may share data with trusted partners to help
                        perform statistical analysis, send you e-mail or postal mail, provide
                        customer support, or arrange for deliveries. All such third parties are
                        prohibited from using your personal information except to provide these
                        services to AgriSA, and they are required to maintain the confidentiality of
                        your information.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA does not use or disclose sensitive personal information, such as
                        race, religion, or political affiliations, without your explicit consent.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA will disclose your personal information, without notice, only if
                        required to do so by law or in the good faith belief that such action is
                        necessary to: (a) conform to the edicts of the law or comply with legal
                        processes served on AgriSA or the website; (b) protect and defend the rights
                        or property of AgriSA; and (c) act under exigent circumstances to protect
                        the personal safety of users of AgriSA or the public.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Data Security Measures */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    7. Data Security Measures
                  </MKTypography>
                  <MKTypography variant="body1" color="text" paragraph>
                    We take the security of client data seriously and employ a comprehensive suite
                    of measures, including:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Encryption:</strong> Data is encrypted both in transit and at rest
                        using industry-standard technologies.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Access Control:</strong> Access to sensitive data is limited to
                        authorized personnel only.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Security Training:</strong> Staff receive regular training on data
                        protection best practices.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Incident Response:</strong> We have a robust incident response plan
                        to address security breaches swiftly.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>International Data Transfers:</strong> When transferring information
                        internationally, we ensure that receiving countries have adequate data
                        protection laws and that appropriate safeguards are in place.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  {/* Disclaimer */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    8. Disclaimer
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>User Agreement with AgriSA:</strong> The AgriSA website consists of
                        various web pages operated by AgriSA. Your use of the AgriSA website is
                        conditioned on your acceptance, without modification, of the terms,
                        conditions, and notices contained herein. By using the AgriSA website, you
                        agree to all such terms, conditions, and notices.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Modification of Terms of Use:</strong> AgriSA reserves the right to
                        change the terms, conditions, and notices under which the website is
                        offered, including, but not limited to, any charges associated with the use
                        of the AgriSA website.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>Links to Third-Party Sites:</strong> The AgriSA website may contain
                        links to other websites (&quot;Linked Sites&quot;). These Linked Sites are
                        not under the control of AgriSA, and we are not responsible for the contents
                        of any Linked Site, including, without limitation, any link contained in a
                        Linked Site or any changes or updates to a Linked Site. AgriSA provides
                        these links solely as a convenience, and the inclusion of any link does not
                        imply endorsement by AgriSA of the site or any association with its
                        operators.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        <strong>No Unlawful or Prohibited Use:</strong> As a condition of your use
                        of the AgriSA website, you warrant that you will not use the website for any
                        purpose that is unlawful or prohibited by these terms, conditions, and
                        notices. You may not use the AgriSA website in any manner that could damage,
                        disable, overburden, or impair the website or interfere with any other
                        party&apos;s use and enjoyment of the website. You may not obtain or attempt
                        to obtain any materials or information through any means not intentionally
                        made available or provided for through the AgriSA website.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Liability Disclaimer */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    9. Liability Disclaimer
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        The information, software, products, and services included in or available
                        through the AgriSA website may contain inaccuracies or typographical errors.
                        Changes are periodically made to the information herein. AgriSA and/or its
                        suppliers may make improvements and/or changes to the website at any time.
                        Advice received via the AgriSA website should not be relied upon for
                        personal, medical, legal, or financial decisions; you should consult an
                        appropriate professional for specific advice tailored to your situation.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA and/or its suppliers make no representations about the suitability,
                        reliability, availability, timeliness, and accuracy of the information,
                        software, products, services, and related graphics contained on the AgriSA
                        website for any purpose. To the maximum extent permitted by applicable law,
                        all such information, software, products, services, and related graphics are
                        provided “as is” without warranty or condition of any kind. AgriSA and/or
                        its suppliers hereby disclaim all warranties and conditions regarding this
                        information, software, products, services, and related graphics, including
                        all implied warranties or conditions of merchantability, fitness for a
                        particular purpose, title, and non-infringement.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        To the maximum extent permitted by applicable law, in no event shall AgriSA
                        and/or its suppliers be liable for any direct, indirect, punitive,
                        incidental, special, consequential damages, or any damages whatsoever,
                        including, without limitation, damages for loss of use, data, or profits,
                        arising out of or in any way connected with the use or performance of the
                        AgriSA website, with the delay or inability to use the website or related
                        services, the provision of or failure to provide services, or for any
                        information, software, products, services, and related graphics obtained
                        through the website, or otherwise arising out of the use of the AgriSA
                        website, whether based on contract, tort, negligence, strict liability, or
                        otherwise, even if AgriSA or any of its suppliers has been advised of the
                        possibility of damages.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        If you are dissatisfied with any portion of the AgricultureSA.io website or
                        with any of these terms of use, your sole and exclusive remedy is to
                        discontinue using the website.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Termination / Access Restriction */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    10. Termination / Access Restriction
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        AgriSA reserves the right, in its sole discretion, to terminate your access
                        to the AgriSA Website and the related services or any portion thereof at any
                        time, without notice. Use of the AgriSA Website is unauthorized in any
                        jurisdiction that does not give effect to all provisions of these terms and
                        conditions, including without limitation this paragraph. You agree that no
                        joint venture, partnership, employment, or agency relationship exists
                        between you and AgriSA as a result of this agreement or use of the AgriSA
                        Website. AgriSA&apos;s performance of this agreement is subject to existing
                        laws and legal process, and nothing contained in this agreement is in
                        derogation of AgriSA&apos;s right to comply with governmental, court and law
                        enforcement requests or requirements relating to your use of the AgriSA
                        Website or information provided to or gathered by AgriSA with respect to
                        such use.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        Unless otherwise specified herein, this agreement constitutes the entire
                        agreement between the user and AgriSA with respect to the AgriSA Website and
                        it supersedes all prior or contemporaneous communications and proposals,
                        whether electronic, oral or written, between the user and AgriSA with
                        respect to the AgriSA Website.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Use of Cookies */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    11. Use of Cookies
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        The AgriSA Website uses “cookies” to help you personalize your online
                        experience. A cookie is a text file that is placed on your hard disk by a
                        Web page server. Cookies cannot be used to run programs or deliver viruses
                        to your computer.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        One of the primary purposes of cookies is to provide a convenience feature
                        to save you time. The purpose of a cookie is to tell the Web server that you
                        have returned to a specific page. When you return to the same AgriSA
                        Website, the information you previously provided can be retrieved, so you
                        can easily use the AgriSA features that you customized.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        You have the ability to accept or decline cookies. If you choose to decline
                        cookies, you may not be able to fully experience the interactive features of
                        the AgriSA services or AgriSA Website(s) you visit.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Copyright and Trademark Notices */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                    12. Copyright and Trademark Notices
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        All contents of the AgricultureSA.io Website are: © AgriSA 2024 and/or its
                        suppliers. All rights reserved.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        The names of actual companies and products mentioned herein may be the
                        trademarks of their respective owners.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        The example companies, organizations, products, people and events depicted
                        herein are fictitious. No association with any real company, organization,
                        product, person, or event is intended or should be inferred.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body1" color="text">
                        Any rights not expressly granted herein are reserved.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Continuous Improvement in Privacy Practices */}
                  <MKTypography variant="h5" component="h2" mb={3}>
                    13. Continuous Improvement in Privacy Practices
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    AgriSA will occasionally update this Statement of Privacy to reflect company and
                    customer feedback. AgriSA encourages you to periodically review this Statement
                    to be informed of how AgriSA is protecting your information.
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We are committed to continuously improving our privacy practices through:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Regular Policy Reviews:</strong> Updating our policies to reflect
                        changes in laws and standards.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Technology Updates:</strong> Assessing and upgrading our security
                        measures.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Training and Awareness:</strong> Providing ongoing training for
                        employees.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Stakeholder Engagement:</strong> Collaborating with clients and
                        experts for feedback.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Impact Assessments:</strong> Conducting assessments for new projects
                        to identify privacy issues.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Adapting to New Challenges */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    14. Adapting to New Challenges
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We are dedicated to adapting our policies and practices to address emerging
                    challenges in data protection, ensuring the highest level of privacy and
                    security.
                  </MKTypography>

                  {/* Section: Client Communication and Engagement */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    15. Client Communication and Engagement
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We prioritize clear communication about our privacy practices through:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Regular Updates:</strong> Informing clients about changes to our
                        policies.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Accessibility:</strong> Making our privacy policy easily
                        understandable.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Feedback Mechanism:</strong> Encouraging client feedback on our
                        privacy practices.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Handling Privacy Concerns and Complaints */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    16. Handling Privacy Concerns and Complaints
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We are committed to addressing privacy concerns and complaints:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Responsive System:</strong> A system is in place for promptly
                        addressing issues.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Internal Review:</strong> Privacy concerns undergo a thorough
                        internal review.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Regulatory Compliance:</strong> Clients can lodge complaints with
                        relevant authorities if needed.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Privacy by Design and Default */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    17. Privacy by Design and Default
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We incorporate privacy by design and default principles into our operations,
                    ensuring privacy is integrated into our processes:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Proactive Measures:</strong> Anticipating and preventing privacy
                        issues.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Minimum Data Processing:</strong> Limiting data collection to what
                        is necessary.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Secure Development Practices:</strong> Developing systems with
                        robust security features.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Regular Assessments:</strong> Conducting assessments to ensure
                        compliance with privacy principles.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Staff Training and Awareness Programs */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    18. Staff Training and Awareness Programs
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    Understanding that the human element is vital, we invest in training and
                    awareness:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Comprehensive Training:</strong> All employees receive training on
                        data protection laws and policies.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Role-specific Training:</strong> Employees handling sensitive data
                        receive additional training.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Awareness Campaigns:</strong> Regular campaigns emphasize the
                        importance of privacy adherence.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Proactive Measures for Privacy Breach Detection and Response */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    19. Proactive Measures for Privacy Breach Detection and Response
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We have systems in place for swift detection and response to privacy breaches:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Breach Response Plan:</strong> A well-defined plan outlines steps
                        for managing breaches.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Post-Incident Analysis:</strong> Conducting analyses to prevent
                        future occurrences.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Future Outlook on Privacy and Data Protection */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    20. Future Outlook on Privacy and Data Protection
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    AgriSA is committed to remaining at the forefront of privacy and data protection
                    by:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Adapting to Emerging Technologies:</strong> Assessing new
                        technologies for privacy implications.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Stakeholder Engagement:</strong> Ongoing dialogue with clients and
                        regulators.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Investment in Privacy Innovation:</strong> Investing in
                        methodologies that enhance data protection.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Compliance with Evolving Privacy Laws */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    21. Compliance with Evolving Privacy Laws
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    We proactively engage with evolving privacy legislation:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Legal Team Engagement:</strong> Our legal team ensures compliance
                        with global privacy laws.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Privacy Impact Assessments:</strong> Conducting assessments for new
                        projects to evaluate risks.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Enhancing Client Relationships through Privacy */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    22. Enhancing Client Relationships through Privacy
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    Robust privacy practices are essential for building trust:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Transparency:</strong> Open communication fosters trust among
                        clients.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Client Empowerment:</strong> Providing control over data strengthens
                        relationships.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li" mb={1}>
                      <MKTypography variant="body1" color="text">
                        <strong>Privacy as a Value Proposition:</strong> Our commitment to privacy
                        differentiates us.
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  {/* Section: Ethical Considerations */}
                  <MKTypography variant="h5" component="h2" mt={6} mb={3}>
                    23. Ethical Considerations
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={3}>
                    At AgriSA, ethical practices are at the core of our operations, ensuring that we
                    not only comply with legal obligations but also uphold the highest standards of
                    integrity in handling client information.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={0} px={3} mt={2}>
        <CustomFooter />
      </MKBox>
    </>
  );
}

export default Privacy;
