// CallToAction.jsx

import PropTypes from "prop-types";
import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
//import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

/**
 * CallToAction Component
 *
 * Displays a call-to-action section with a background image, left-aligned text, and a button.
 *
 * Props:
 * - image: URL of the background image.
 * - titleLeft: Text for the left title.
 * - titleRight: Text for the right title.
 * - buttonText: Text for the button.
 * - buttonLink: URL or route for the button action.
 */
function CallToAction({ image, titleLeft, titleRight, buttonText }) {
  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { rgba, linearGradient },
        borders: { borderRadius },
      }) => ({
        backgroundImage: `${linearGradient(
          rgba(gradients.dark.main, 0.0),
          rgba(gradients.dark.state, 0.0)
        )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.xl,
        px: 3,
        mb: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: { xs: "auto", md: "100px", lg: "150px" },
        py: { xs: 2, md: 1, lg: 1 },
        boxShadow: ({ boxShadows: { lg } }) => lg,
      })}
    >
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        {/* Left Title */}
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          display="flex"
          justifyContent={{ xs: "center", lg: "flex-start" }}
        >
          <MKTypography variant="h5" color="white" fontWeight="bold">
            {titleLeft}
          </MKTypography>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={4} lg={6} display="flex" justifyContent="center">
          <MKButton
            variant="gradient"
            color="info"
            size="large"
            component="a"
            href="https://share-eu1.hsforms.com/1TSSeeAwBRGywAoKDV6iLnA2dypu8"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ boxShadow: "none" }}
          >
            {buttonText}
          </MKButton>
        </Grid>

        {/* Right Title */}
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          display="flex"
          justifyContent={{ xs: "center", sm: "center", lg: "flex-end" }}
        >
          <MKTypography variant="h5" color="white" fontWeight="bold">
            {titleRight}
          </MKTypography>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the CallToAction
CallToAction.propTypes = {
  image: PropTypes.string.isRequired,
  titleLeft: PropTypes.string.isRequired,
  titleRight: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default CallToAction;
